import { MDXProvider } from '@mdx-js/react'
import FooterCTA from 'components/FooterCTA'
import { RightArrow } from 'components/Icons/Icons'
import Layout from 'components/Layout'
import Link from 'components/Link'
import { Section } from 'components/Section'
import { AppsDocumentation } from 'components/AppsDocumentation'
import { SEO } from 'components/seo'
import { getSeoGatsbyImage } from 'utils/googleData'
import { SEO2 } from 'components/seo2'
import TutorialsSlider from 'components/TutorialsSlider'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { graphql } from 'gatsby'
import React from 'react'
import { shortcodes } from '../mdxGlobalComponents'
import SectionLinks from 'components/SectionLinks'
import PostLayout from 'components/PostLayout'
import SidebarSection from 'components/PostLayout/SidebarSection'
import Topics from 'components/PostLayout/Topics'
import { capitalizeFirstLetter } from '../utils/dateutils'

// export const Head = ({ location, params, data: { pageData } , pageContext }) => (
//     <SEO2
//     title={`${pageData?.frontmatter?.title}`}
//     description={pageData?.frontmatter?.description || pageData?.excerpt}
//     article
//     image={ `/images/marketplace/${pageData?.frontmatter?.slug?.split('/')[2]}.png`  }
// />
//   )

const AppSidebar = ({ maintainer,type}) => {
    return (
        <>
            {maintainer && (
                <SidebarSection title="Maintainer">
                    <Topics
                        topics={[
                            {
                                name: capitalizeFirstLetter(maintainer),
                                url: `/marketplace?filter=maintainer&value=${maintainer}`,
                            },
                        ]}
                    />
                </SidebarSection>
            )}
            {type?.length > 0 ? (
                <SidebarSection title={`Type${type?.length === 1 ? '' : 's'}`}>
                    <Topics
                        topics={type?.map((type) => ({
                            name: capitalizeFirstLetter(type),
                            url: `/marketplace?filter=type&value=${type}`,
                        }))}
                    />
                </SidebarSection>
            ) : null}
        </>
    )
}

    export default function App(pageProps) {
        const { data, location, pageContext, params, children } = pageProps
       const { next, previous }  =  pageContext
    const { pageData, documentation } = data
    const {
        body,
        excerpt,
        parent,
        headings,
        fields: { slug },
    } = pageData
    const { title, subtitle, thumbnail, description, maintainer,pluginType } = pageData?.frontmatter
    const thumbnailImg = getSeoGatsbyImage({ googleImages: [parent?.images, parent?.images2]})
    const components = {
        ...shortcodes(pageProps),
        Documentation: (props) => <AppsDocumentation {...props} {...pageProps} />,
        TutorialsSlider: (props) => <TutorialsSlider {...props} {...pageProps} />,
    }
    return (
        <Layout >
               <SEO
    title={`${pageData?.frontmatter?.title}`}
    description={pageData?.frontmatter?.description || pageData?.excerpt}
    article
    image={ `/images/marketplace/${pageData?.frontmatter?.slug?.split('/')[2]}.png`  }
/>
            <PostLayout
                searchFilter="marketplace"
                sidebar={<AppSidebar maintainer={maintainer} type={pluginType}/>}
                menu={[
                    {
                        name: 'Marketplace Apps',
                    },
                    {
                        name: 'All',
                        url: '/marketplace',
                    },
                    {
                        name: 'Collections',
                        url: '/marketplace?filter=type&value=collections',
                    },
                    {
                        name: 'Productivity',
                        url: '/marketplace?filter=type&value=productivity',
                    },
                    {
                        name: 'Resident-Services',
                        url: '/marketplace?filter=type&value=resident-services',
                    },
                    {
                        name: 'PM-Services',
                        url: '/marketplace?filter=type&value=pm-services',
                    },
                    {
                        name: 'Associations',
                        url: '/marketplace?filter=maintainer&value=associations',
                    },
                    {
                        name: 'Other',
                        url: '/marketplace?filter=maintainer&value=Other',
                    },
                ]}
                breadcrumb={[{ name: 'Marketplace', url: '/marketplace' }, { name: title }]}
            >
                {thumbnailImg && (
                    <figure className="m-0 text-center">
                        <GatsbyImage image={thumbnailImg} alt={title} className="h-24 mx-auto mb-6" />
                    </figure>
                )}
                <h1 className="text-center mt-0 mb-12 hidden lg:block">{title}</h1>
                <article>
                    <MDXProvider components={components}>
                        <MDXRenderer>{body}</MDXRenderer>
                    </MDXProvider>
                </article>
                <div className="mt-12">
                    <SectionLinks next={next} previous={previous} />
                </div>
                {/* <div className="mb-12">
                    <FooterCTA />
                </div> */}
            </PostLayout>
        </Layout>
    )
}

export const query = graphql`
query App($id: String!, $documentation: String!) {
    pageData: mdx(id: {eq: $id}) {
      body
      excerpt
      fields {
        slug
        pageViews
      }
      frontmatter {
        date
        title
        description
        pluginType
        maintainer
        thumbnail {
          childImageSharp {
            gatsbyImageData(placeholder: NONE)
          }
        }
      }
      parent {
        ... on GoogleDocs {
            images {
                name
                publicURL
                childImageSharp {
                    gatsbyImageData
                }
            }
            images2: images {
                name
                publicURL
                childImageSharp {
                    gatsbyImageData(width: 256, height: 256)
                }
            }
          footer {
            image {
              publicURL
              childImageSharp {
                gatsbyImageData(placeholder: NONE)
              }
            }
          }
        }
      }
    }
    documentation: mdx(fields: {slug: {eq: $documentation}}) {
      fields {
        slug
      }
      headings {
        depth
        value
      }
    }
  }
`
